import RichText from 'components/UI/Richtext';
import { ReactElement } from 'react';
import { IComponentTextOnlyFields } from 'types/contentful';

export default function TextOnly({ text, openInNewTab }: IComponentTextOnlyFields): ReactElement {
  return (
    <RichText
      classNames={
        'prose prose-sm sm:prose lg:prose-lg max-w-none px-4 py-6 lg:py-10 md:px-20 lg:px-32 text-lg break-words ' +
        (openInNewTab ? 'openInNewTab' : 'doNotOpenInNewTab')
      }
      document={text}
    />
  );
}
